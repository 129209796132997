
// lib
import { Vue, Component } from 'vue-property-decorator'

// app
import AppNavbar from '@/app/layouts/_part/app-navbar.vue'
import AppAppbar from '@/app/layouts/_part/app-appbar.vue'
import SmartNotify from '@/app/notify/smart-notify.vue'

@Component({
  components: {
    AppNavbar,
    AppAppbar,
    SmartNotify
  }
})
export default class DefaultLayout extends Vue {}
