
// lib
import { Vue, Component } from 'vue-property-decorator'

// app
import { TNotify } from './shared/types'

@Component
export default class SmartNotify extends Vue {
  isOpen = false

  value: TNotify = {
    type: 'info',
    message: ''
  }

  get color (): string {
    switch (this.value.type) {
      case 'error':
        return 'red'
      case 'success':
        return 'green'
      case 'warn':
        return 'orange'
      default:
        return ''
    }
  }

  created (): void {
    this.$eventBus.$on('notify', (options: TNotify) => {
      this.isOpen = true
      this.value = options
    })
  }
}
